import React from "react";
import PropTypes from "prop-types";

import styled, { useTheme } from "styled-components";

const ProfilePic = ({ src, alt, size, borderRadius, style, className }) => {

    let color = useTheme().accent;

    return (
        <div
        style={{
            flex: 1,
            justifyContent: "center",
            margin: "120px auto 50px auto"

        }}
        >
            <img
            src={src}
            alt={alt}
            className={className}
            style={{
                width: size,
                height: size,
                borderRadius: borderRadius,
                border: `2px solid ${color}`,
                objectFit: "cover",
                marginRight: "50px"
            }}
            />
        </div>
    );
};

ProfilePic.propTypes = {
    src: PropTypes.string.isRequired, // Image source
    alt: PropTypes.string.isRequired, // Accessibility text
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Image size
    borderRadius: PropTypes.string, // Border radius (e.g., "50%" for circular)
    style: PropTypes.object, // Inline styles
    className: PropTypes.string, // Additional CSS classes
};

ProfilePic.defaultProps = {
    size: "200px", // Default size
    borderRadius: "50%", // Default to circular shape
    style: {}, // No additional inline styles
    className: "profile-pic", // No additional CSS classes
};

export default ProfilePic;